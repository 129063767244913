<template>
  <div class="vendor-delivery">
    <v-card outlined>
      <div class="pl-lg-9 px-sm-7 py-sm-8 py-3 px-3">
        <div class="text-h6 font-weight-bold">Vendor delivery schedule</div>
        <div class="text--text">Delivery schedule will be used for showing available delivery time slots</div>
      </div>
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="headers"
        :items="data"
        item-key="id"
        :items-per-page="-1"
        class="d-md-block d-none"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, i) in items" :key="item.id" style="height: 70px">
              <td>
                <v-select
                  @change="item.id ? updateItem(item) : ''"
                  v-model="item.day"
                  :items="weekList"
                  item-text="title"
                  item-value="name"
                  placeholder="Day"
                  outlined
                  dense
                  hide-details
                  class="field46"
                  height="46"
                ></v-select>
              </td>
              <td>
                <v-text-field
                  class="field46"
                  :value="item.time_from"
                  @click="openClock(item.time_from, i, 's')"
                  @click:append="openClock(item.time_from, i, 's')"
                  @change="item.id ? updateItem(item) : ''"
                  :error-messages="timeFromErrors"
                  placeholder="__:__"
                  append-icon="mdi-clock-time-three"
                  hide-details
                  outlined
                  dense
                  color="primary"
                  height="46"
                  readonly
                  :disabled="!item.day"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  class="field46"
                  :value="item.time_to"
                  @click="openClock(item.time_to, i, 'e')"
                  @click:append="openClock(item.time_to, i, 'e')"
                  @change="item.id ? updateItem(item) : ''"
                  :error-messages="timeToErrors"
                  placeholder="__:__"
                  append-icon="mdi-clock-time-three"
                  hide-details
                  outlined
                  dense
                  color="primary"
                  height="46"
                  readonly
                  :disabled="!item.time_from"
                ></v-text-field>
              </td>
              <td class="d-flex align-center" style="height: 70px !important">
                <v-text-field
                  @change="item.id ? updateItem(item) : addItem(item)"
                  v-model="item.priceR"
                  placeholder="0"
                  suffix="SAR"
                  type="number"
                  :error-messages="priceErrors"
                  outlined
                  dense
                  hide-details
                  class="field46"
                  height="46"
                  :disabled="!item.time_to"
                ></v-text-field>
                <v-btn v-if="item.id" @click="(modal = true), (idx = i)" icon class="ms-2">
                  <v-icon color="gray lighten-1">mdi-close</v-icon>
                </v-btn>
                <v-card color="transparent" v-else tile flat min-width="36" height="36" class="ms-2"></v-card>
              </td>
            </tr>
            <tr v-if="tableErrors.length">
              <td colspan="4" class="text-caption error--text px-4" style="height: 25px !important">{{ tableErrors.join(' ') }}</td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div class="d-block d-md-none">
        <div v-for="(item, i) in data" :key="item.id" class="px-4 pt-2">
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Day</div>
            <v-select
              style="max-width: 156px"
              @change="item.id ? updateItem(item) : ''"
              v-model="item.day"
              :items="weekList"
              item-text="title"
              item-value="name"
              placeholder="Day"
              outlined
              dense
              hide-details
              class="field46"
              height="46"
            ></v-select>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Begin time</div>
            <v-text-field
              style="max-width: 156px"
              class="field46"
              :value="item.time_from"
              @click="openClock(item.time_from, i, 's')"
              @click:append="openClock(item.time_from, i, 's')"
              @change="item.id ? updateItem(item) : ''"
              :error-messages="timeFromErrors"
              placeholder="__:__"
              append-icon="mdi-clock-time-three"
              hide-details
              outlined
              dense
              color="primary"
              height="46"
              readonly
              :disabled="!item.day"
            ></v-text-field>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Duration, hours</div>
            <v-text-field
              style="max-width: 156px"
              class="field46"
              :value="item.time_to"
              @click="openClock(item.time_to, i, 'e')"
              @click:append="openClock(item.time_to, i, 'e')"
              @change="item.id ? updateItem(item) : ''"
              :error-messages="timeToErrors"
              placeholder="__:__"
              append-icon="mdi-clock-time-three"
              hide-details
              outlined
              dense
              color="primary"
              height="46"
              readonly
              :disabled="!item.time_from"
            ></v-text-field>
          </div>
          <div class="d-flex my-1">
            <div class="opasity--text f18 font-weight-medium label-table">Number of seats</div>
            <v-text-field
              style="max-width: 156px"
              @change="item.id ? updateItem(item) : addItem(item)"
              v-model="item.priceR"
              placeholder="0"
              suffix="SAR"
              :error-messages="priceErrors"
              outlined
              dense
              hide-details
              class="field46"
              height="46"
              :disabled="!item.time_to"
            ></v-text-field>
            <v-btn v-if="item.id" @click="(modal = true), (idx = i)" icon class="ms-2">
              <v-icon color="gray lighten-1">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
        </div>
      </div>
      <!-- <v-spacer></v-spacer>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 py-3 px-3 text-end">
        <v-btn @click="updateItem" class="rounded" depressed color="primary" large width="136">Save</v-btn>
      </div> -->
    </v-card>
    <v-dialog v-model="modal" width="480">
      <v-card class="pa-4">
        <div class="text-h6 font-weight-bold mb-3">Do you really want to remove delivery day?</div>
        <div class="text-end">
          <v-btn @click="modal = false" outlined class="rounded" color="gray lighten-1" width="106" height="30">
            <span class="text-body-2 black--text">Cancel</span>
          </v-btn>
          <v-btn @click="removeItem" depressed class="error rounded ms-3" width="106" height="30">
            <span class="text-body-2">Remove</span>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="timeModal" persistent width="290px">
      <v-time-picker v-if="timeModal" v-model="modalData.time" full-width format="24hr">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="doneClock">OK</v-btn>
      </v-time-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      modal: false,
      timeModal: false,
      idx: '',
      modalData: {},
      headers: [
        { text: 'Day', value: 'day', sortable: false },
        { text: 'Begin time', value: 'time_from', sortable: false },
        { text: 'End time', value: 'time_to', sortable: false },
        { text: 'Price', value: 'price', sortable: false },
      ],
      weekList: [
        { name: 'monday', title: 'Monday' },
        { name: 'tuesday', title: 'Tuesday' },
        { name: 'wednesday', title: 'Wednesday ' },
        { name: 'thursday', title: 'Thursday ' },
        { name: 'friday', title: 'Friday ' },
        { name: 'saturday', title: 'Saturday ' },
        { name: 'sunday', title: 'Sunday ' },
      ],
      error: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('getVendorItemSchedule', this.profile.product_vendor.id);
    }, 300);
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor delivery day saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor delivery day updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Product vendor delivery day removed successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    openClock(t, i, j) {
      this.timeModal = true;
      this.modalData.time = t;
      this.modalData.i = i;
      this.modalData.j = j;
    },
    doneClock() {
      this.timeModal = false;
      this.modalData.j == 's'
        ? (this.data[this.modalData.i].time_from = this.modalData.time)
        : (this.data[this.modalData.i].time_to = this.modalData.time);
    },
    async removeItem() {
      await this.$store
        .dispatch('deleteVendorItemSchedule', { id: this.profile.product_vendor.id, data: this.data[this.idx].id })
        .then(() => {
          this.modal = false;
          this.data.splice(this.idx, 1);
          this.notifi('remove');
        });
    },
    async addItem(item) {
      this.error = [];
      const data = new Object();
      data.day = item.day;
      data.time_from = item.time_from;
      data.time_to = item.time_to;
      data.price = item.priceR * 100;
      await this.$store
        .dispatch('addVendorItemSchedule', { id: this.profile.product_vendor.id, data: data })
        .then((res) => {
          res.data.priceR = res.data.price / 100 || '';
          this.data[this.data.length - 1] = res.data;
          this.data.push({ time_from: null, time_to: null });
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async updateItem(item) {
      this.error = [];
      const data = new Object();
      data.day = item.day;
      data.time_from = item.time_from;
      data.time_to = item.time_to;
      data.price = item.priceR * 100;
      await this.$store
        .dispatch('updateVendorItemSchedule', { vendor: this.profile.product_vendor.id, id: item.id, data: data })
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.vendorSchedule;
    },
    profile() {
      return this.$store.getters.profile;
    },
    dayErrors() {
      const errors = [];
      this.error.find((item) => item == 'day__required') && errors.push('Please select day');
      this.error.find((item) => item == 'day__invalid') && errors.push('Selected day is not valid');
      return errors;
    },
    timeFromErrors() {
      const errors = [];
      this.error.find((item) => item == 'time_from__required') && errors.push('Please enter begin time');
      this.error.find((item) => item == 'time_from__invalid') && errors.push('Provided begin time is not valid');
      return errors;
    },
    timeToErrors() {
      const errors = [];
      this.error.find((item) => item == 'time__required') && errors.push('Please enter end time');
      this.error.find((item) => item == 'time__invalid') && errors.push('Provided end time is not valid');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
    tableErrors() {
      const errors = [];
      this.error.find((item) => item == 'internal') && errors.push('Sorry, but window with the same day and/or time already exists');
      this.error.find((item) => item == 'window__exists') && errors.push('Sorry, but window with the same day and/or time already exists');
      this.error.find((item) => item == 'day__required') && errors.push('Please select day');
      this.error.find((item) => item == 'day__invalid') && errors.push('Selected day is not valid');
      this.error.find((item) => item == 'time_from__required') && errors.push('Please enter begin time');
      this.error.find((item) => item == 'time_from__invalid') && errors.push('Provided begin time is not valid');
      this.error.find((item) => item == 'time__required') && errors.push('Please enter end time');
      this.error.find((item) => item == 'time__invalid') && errors.push('Provided end time is not valid');
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setVendorItemSchedule');
  },
};
</script>
